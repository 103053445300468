import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import Type from '../components/Type'
import Isvg from 'react-inlinesvg'
import LazyLoad from 'react-lazyload'
import { injectIntl, defineMessages } from 'gatsby-plugin-intl'

import { Oops } from '../components/Images'

export const messageDescriptors = defineMessages({
  title: {
    id: 'pages.404.title',
    defaultMessage: 'Page not found',
  },
  heading: {
    id: 'pages.404.heading',
    defaultMessage: "Unfortunately, that page doesn't seem to exist."
  },
  subHeading: {
    id: 'pages.404.subHeading',
    defaultMessage: 'Looks like something might have moved :('
  }
})

const Image = styled(Isvg)`
  svg {
    width: 80%;
    @media only screen and (min-width: 600px) {
      width: 100%;
    }
  }
`

const NotFoundPage = ({ intl }) => (
  <Layout title={intl.formatMessage(messageDescriptors.title)}>
    <Hero>
      <Section large>
        <Wrapper tc small>
          <Type variant="h1" f1_ns lh dark_gray mb0>
            {intl.formatMessage(messageDescriptors.heading)}
          </Type>
          <Type variant="h2" f5 f4_ns lh mb3 gray fw4>
            {intl.formatMessage(messageDescriptors.subHeading)}
          </Type>
        </Wrapper>
        <Wrapper tc smaller>
          <LazyLoad height={100} offset={100} once>
            <Image src={Oops} />
          </LazyLoad>
        </Wrapper>
      </Section>
    </Hero>

    <Section>
      <Wrapper tc small />
    </Section>
  </Layout>
)

export default injectIntl(NotFoundPage)
